<template>
  <div>
    <CCard v-for="kpi in kpis" :key="kpi.id" class="mt-4">
      <CCardBody>
        <CRow>
          <CCol>
            <h4 class="card-title mb-1">
              {{ kpi.name }}
              <CIcon class="ml-2 text-info cursor-pointer" v-if="kpi.definition" name="cil-magnifying-glass" v-c-tooltip="{content: kpi.definition, placement: 'bottom', boundaries: 'viewport' }" />
            </h4>
            <div class="small text-muted">
              <span class="text-nowrap mr-3"><span class="text-info">ID: </span>{{ kpi.id }}</span>
              <span class="text-nowrap mr-3"><span class="text-info">Owner: </span>{{ getOwner(kpi.owner) }}</span>
              <span class="text-nowrap mr-3"><span class="text-info">Frequency: </span>{{ kpi.frequency }}</span>
              <span class="text-nowrap" v-if="getComputedLabel(kpi.type)"><span class="text-info">Computed: </span>{{ getComputedLabel(kpi.type) }}</span>
            </div>
          </CCol>
        </CRow>
        <KpiChart :kpi_id="kpi.id" />
      </CCardBody>
    </CCard>
    <NoItemsCard v-if="kpis.length == 0" text="No KPI's assigned" />
  </div>
</template>

<script>
import KpiChart from '../charts/KpiChart'
import NoItemsCard from '../../components/NoItemsCard'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'McDashboard',
  data() {
    return {
      activeTabs: [0]
    }
  },
  components: {
    KpiChart,
    NoItemsCard
  },
  computed: {
    ...mapState({
      activeSectionId: state => state.kpiData.activeSectionId,
      activeTabKey: state => state.kpiData.activeTabKey,
    }),
    ...mapGetters({
      getItemById: 'kpiData/getItemById',
      getMcKpisBySection: 'kpiData/getMcKpisBySection',
    }),
    kpis() {
      return this.getMcKpisBySection(this.activeSectionId)
    },
  },
  methods: {
    getOwner(id) {
      if (this.getItemById({name: 'owners', id: id})) {
        return this.getItemById({name: 'owners', id: id}).name
      }
    },
    getComputedLabel(type) {
      switch (type) {
        case 'computed': 
        case 'stacked_computed': 
          return 'average'
        case 'computed_sum': 
        case 'stacked_computed_sum': 
          return 'sum'
        default: return false
      }
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.card-title >>> .arrow:before {
  border-bottom-color: #39f;
  border-top-color: #39f;
}
.card-title >>> .tooltip-old-inner {
  background-color: #fff;
  color: inherit;
  border: 1px solid #39f;
}
</style>